import {
  BoxFlexOne,
  PenDropEditorContainer,
  PenDropEditorContainerDropText,
  PenDropEditorContainerHeader,
} from '@/components/CallFeedTab/styledComponents';
import { PenDropEditorProps } from '@/components/CallFeedTab/types';
import ControlledSelectField from '@/components/ControlledSelectField';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { ControlledNumberFieldWithIncrements } from '../ControlledNumberField';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import IconButton from '@mui/material/IconButton';
import { useOrgSettings } from '../OrgSettings/OrgSettings';

export const PenDropEditor = ({
  dropIndex,
  penIndex,
  rations,
  control,
  onDeleteDrop,
  onCalledLbsChange,
  onRationChange,
  dropId,
  disabled,
}: PenDropEditorProps): JSX.Element => {
  const isPhoneSize = useIsPhoneSize();
  const [{ settings }] = useOrgSettings();
  const { 'feeding.afIncrement': settingCallFeedAfIncrement } = settings;

  return (
    <PenDropEditorContainer data-testid="call-feed-drop-editor" data-dropid={dropId}>
      <PenDropEditorContainerHeader>
        <PenDropEditorContainerDropText>Drop {dropIndex + 1}</PenDropEditorContainerDropText>
        <IconButton
          disabled={disabled}
          data-testid="call-feed-drop-editor-delete-button"
          onClick={() => onDeleteDrop?.(dropIndex)}
        >
          <DeleteIcon color={disabled ? 'disabled' : 'primary'} sx={{ fontSize: 16 }} />
        </IconButton>
      </PenDropEditorContainerHeader>

      <Stack spacing={1} direction={isPhoneSize ? 'row' : 'column'}>
        <BoxFlexOne>
          <ControlledSelectField
            name={`pens.${penIndex}.drops.${dropIndex}.rationId`}
            control={control}
            disabled={disabled}
            data-testid="call-feed-drop-editor-ration-select-field"
            onChange={onRationChange}
            rules={{ required: 'Select a Ration' }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {rations.map((ration) => (
              <MenuItem key={ration.id} value={ration.id}>
                {ration.name}
              </MenuItem>
            ))}
          </ControlledSelectField>
        </BoxFlexOne>
        <BoxFlexOne>
          <ControlledNumberFieldWithIncrements
            control={control}
            name={`pens.${penIndex}.drops.${dropIndex}.calledLbs`}
            increment={settingCallFeedAfIncrement}
            onChange={(value) => onCalledLbsChange?.(dropIndex, value)}
          />
        </BoxFlexOne>
      </Stack>
    </PenDropEditorContainer>
  );
};
