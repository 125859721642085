import {
  DropWithRationIngredientWithIngredient,
  getCalledLbsFromDmiPerHead,
  getDmiPerHeadByDrops,
  getPercentDryByRationIngredients,
  twoDecimals,
} from '@/common/utils/math';
import { bunkScoreOptions } from '@/components/BunkScoringTab';
import { PenDropEditor } from '@/components/CallFeedTab/PenDropEditor';
import {
  AddDropButton,
  AddDropButtonWrap,
  BoxFlexOne,
  FieldsStack,
  PenDropEditorContainer,
  PenDropEditorContainerDropText,
  PenDropEditorContainerHeader,
  PenDropsListContainer,
  PenDropsListDropsContainer,
  PenDropsListDropsScroller,
  PenDropsListDropsStack,
  PenDropsListHeader,
  PenDropsListHeaderBunkScoreText,
  PenDropsListHeaderInfo,
  PenDropsListHeaderPenName,
  PenDropsListHeaderStatText,
  StackFlexOne,
  TotalCallAsFedBox,
} from '@/components/CallFeedTab/styledComponents';
import { CallBy, CalLFeedFormValues, PenDrop, PenDropsListProps } from '@/components/CallFeedTab/types';
import { ControlledNumberFieldWithIncrements } from '@/components/ControlledNumberField';
import ControlledSelectField from '@/components/ControlledSelectField';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import { Drop, LoadStage } from '@/web-types';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, MenuItem, Stack } from '@mui/material';
import { useCallback, useMemo, useRef } from 'react';
import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { formatProjectedWeightLbs } from '../helpers/format';
import { callFeedKvStore } from './callFeedKvStore';
import { sumBy } from 'lodash';
import { showConfirmationModal } from '@/components/ConfirmationModal/ConfirmationModal';
import { showPenDropDetailsModal } from '@/components/CallFeedTab/PenDropDetailsModal';
import { useOrgSettings } from '@/components/OrgSettings/OrgSettings';
import { LinkButton } from '@/components/LinkButton/LinkButton';
import Typography from '@mui/material/Typography';
import { InfoOutlined } from '@mui/icons-material';
import { showLotsInPenModal } from '@/components/CallFeedTab/LotsInPenModal';

const Empty = [] as never[];

export const PenDropsList = ({
  pens,
  penIndex,
  rations,
  rationIngredientsMap,
  inDialog = false,
  isTodaySelected,
  selectedDate,
  onHideDetails,
  formMethods,
}: PenDropsListProps): JSX.Element => {
  const pen = pens[penIndex];
  const { control, getValues, setValue } = formMethods;
  const isPhoneSize = useIsPhoneSize();
  const [{ settings }] = useOrgSettings();
  const cache = useRef(new Map<string, number>());

  const {
    'feeding.dmiPerHeadIncrement': settingCallFeedDmiPerHeadIncrement,
    'feeding.afPerHeadIncrement': settingCallFeedAfPerHeadIncrement,
    'feeding.afIncrement': settingCallFeedAfIncrement,
  } = settings;

  // We do not use the return value of useFieldArray() beause it doesn't update when you change something inside the array
  // The value returned by useWatch() does update
  // But if you don't call useFieldArray() then isDirty doesn't get set even if you pass {shouldDirty: true} to setValue()
  // This is probably a bug in react-hook-forms but the documentation is so vague that it isn't clear how it should work
  useFieldArray({ control, name: `pens.${penIndex}.drops` });
  const [selectedAllRationId, callBy, maybeDrops] = useWatch({
    control,
    name: [`pens.${penIndex}.selectedAllRationId`, `callBy`, `pens.${penIndex}.drops`],
  });
  const drops = maybeDrops ?? Empty;

  const penHasMultipleRations = useMemo(() => new Set(drops.map((drop: any) => drop.rationId)).size > 1, [drops]);

  const disablePerHeadNumberFields = useMemo(
    () => drops.length === 0 || !!drops?.find((drop) => !drop.rationId) || penHasMultipleRations,
    [drops, penHasMultipleRations]
  );

  const disableTotalCallNumberField = useMemo(
    () =>
      drops.length === 0 ||
      !!drops?.find((drop) => drop?.load?.stage && drop?.load?.stage !== LoadStage.NotYetStarted) ||
      !!drops?.find((drop) => !drop.rationId) ||
      penHasMultipleRations ||
      !selectedAllRationId,
    [drops, penHasMultipleRations, selectedAllRationId]
  );

  const headerText = useMemo(() => {
    const result = [`${pen.numHeads ?? 0} Head`];
    if (pen.projectedCurrentWeightPerHeadLbs) {
      result.push(formatProjectedWeightLbs(pen.projectedCurrentWeightPerHeadLbs ?? 0, true, false));
    }

    if (isPhoneSize) {
      result.length = 1;
    }

    return result.join(' ｜ ');
  }, [isPhoneSize, pen.numHeads, pen.projectedCurrentWeightPerHeadLbs]);

  const updateTotalAndDmi = useCallback(
    (props?: { skipAsFedPerHead?: boolean; skipTotalWeight?: boolean; skipDmiPerHead?: boolean }) => {
      const drops = getValues().pens[penIndex].drops as PenDrop[];
      const dmiPerHead = getDmiPerHeadByDrops(drops, rationIngredientsMap, true);
      const totalCalledLbs = drops.reduce((acc: number, drop: PenDrop) => acc + (drop.calledLbs ?? 0), 0);

      if (!props?.skipAsFedPerHead)
        setValue(`pens.${penIndex}.asFedPerHead`, twoDecimals(totalCalledLbs / pen.numHeads), { shouldDirty: false });
      if (!props?.skipTotalWeight)
        setValue(`pens.${penIndex}.totalWeight`, twoDecimals(totalCalledLbs), { shouldDirty: false });
      if (!props?.skipDmiPerHead)
        setValue(`pens.${penIndex}.dmiPerHead`, twoDecimals(dmiPerHead), { shouldDirty: false });
    },
    [getValues, pen.numHeads, penIndex, rationIngredientsMap, setValue]
  );

  const updateDropsByAmount = useCallback(
    (requestedTotal = 0) => {
      // We will distribute the changes across the drops that have not been started
      const allDrops = (getValues().pens[penIndex].drops as PenDrop[]).map((drop, index) => ({ drop, index }));
      const unstartedDrops = allDrops.filter(
        ({ drop }) => (drop.load?.stage ?? LoadStage.NotYetStarted) === LoadStage.NotYetStarted
      );
      const drops = unstartedDrops.length === 0 ? allDrops : unstartedDrops;
      const previousTotalAll = sumBy(allDrops, ({ drop }) => drop.calledLbs ?? 0);
      const previousTotal = sumBy(drops, ({ drop }) => drop.calledLbs ?? 0);
      const newTotal = requestedTotal - previousTotalAll + previousTotal;

      // Each drop has a lastOver0CalledLbs value that is set when the calledLbs is greater than 0
      // This is used to preserve the original calledLbs value when the total is 0
      // if all lastOver0CalledLbs are 0, we need to calculate the ratio based on the current total
      // Typical scenario is when the total is 0 and user have drops with 0 calledLbs initally
      const lastOver0Total = sumBy(drops, ({ drop }) => drop.lastOver0CalledLbs ?? 0);

      // we will distribute the changes across each drop according to each drop's proportion to the total
      // unless the total was zero, in which case we will distribute the changes evenly
      const divisor = previousTotal || lastOver0Total;
      drops.forEach(({ drop, index }) => {
        const calledLbs = (previousTotal ? drop.calledLbs : drop.lastOver0CalledLbs) ?? 0;
        const proportion = divisor
          ? // there was a previous total, so the proportion is based on the previous value's proportion of the previous total
            calledLbs / divisor
          : // the previous total was zero, so the proportion is just the even distribution
            1 / drops.length;
        setValue(`pens.${penIndex}.drops.${index}.calledLbs`, Math.max(Math.round(newTotal * proportion), 0), {
          shouldDirty: true,
        });
      });
    },
    [getValues, penIndex, setValue]
  );

  const updateDropsByAsFedPerHead = useCallback(
    (asFedPerHead = 0) => {
      const newTotal = asFedPerHead * pen.numHeads;
      updateDropsByAmount(newTotal);
    },
    [pen.numHeads, updateDropsByAmount]
  );

  const updateDropsByDmi = useCallback(
    (value: number) => {
      const drops = getValues().pens[penIndex].drops as Drop[];
      const drop = drops[0];
      const percentDry = getPercentDryByRationIngredients(
        rationIngredientsMap.get(drop.rationId) as DropWithRationIngredientWithIngredient['rationIngredients'],
        cache.current,
        drop?.rationId && drop?.rationRevision ? `${drop.rationId}-${drop.rationRevision}` : undefined
      );

      const newTotal = getCalledLbsFromDmiPerHead(value, percentDry, pen.numHeads);
      updateDropsByAmount(newTotal);
    },
    [getValues, pen.numHeads, penIndex, rationIngredientsMap, updateDropsByAmount]
  );

  const onTotalWeightChange = useCallback(
    (value: number) => {
      updateDropsByAmount(value);
      updateTotalAndDmi({ skipTotalWeight: true });
    },
    [updateDropsByAmount, updateTotalAndDmi]
  );

  const onDmiPerHeadChange = useCallback(
    (value: number) => {
      updateDropsByDmi(value);
      updateTotalAndDmi({ skipDmiPerHead: true });
    },
    [updateDropsByDmi, updateTotalAndDmi]
  );

  const onAsPerHeadChange = useCallback(
    (value: number) => {
      updateDropsByAsFedPerHead(value);
      updateTotalAndDmi({ skipAsFedPerHead: true });
    },
    [updateDropsByAsFedPerHead, updateTotalAndDmi]
  );

  const updateCallBy = useCallback(
    (callBy: CallBy) => {
      setValue(`callBy`, callBy, { shouldDirty: false });
      callFeedKvStore.set({ callBy });
    },
    [setValue]
  );

  const onSelectedAllRationIdChange = useCallback(
    (rationId: number) => {
      const drops = getValues().pens[penIndex].drops as Drop[];
      drops.forEach((drop, index) => {
        setValue(`pens.${penIndex}.drops.${index}.rationId`, rationId, { shouldDirty: true });
        if (rationId) {
          const rationRevision = rations.find((r) => r.id === rationId)!.currentRevision;
          setValue(`pens.${penIndex}.drops.${index}.rationRevision`, rationRevision, { shouldDirty: true });
        }
      });
      updateTotalAndDmi();
    },
    [getValues, penIndex, rations, setValue, updateTotalAndDmi]
  );

  const updateSelectedAllRation = useCallback(() => {
    const drops = getValues().pens[penIndex].drops as Drop[];
    const hasSameRation = new Set(drops.map((drop) => drop.rationId)).size === 1;
    setValue(`pens.${penIndex}.selectedAllRationId`, hasSameRation ? drops[0]?.rationId : null, {
      shouldDirty: false,
    });

    updateTotalAndDmi();
  }, [getValues, penIndex, setValue, updateTotalAndDmi]);

  const addDrop = useCallback(() => {
    const pen = getValues().pens[penIndex];
    const drops = pen.drops as PenDrop[];
    const { id, ...lastDrop } = drops[drops.length - 1] || {
      rationId: rations[0].id,
      rationRevision: rations[0].currentRevision,
      numHeads: pen.numHeads,
    };

    setValue(
      `pens.${penIndex}.drops`,
      [
        ...drops,
        {
          ...lastDrop,
          _uuid: uuid(),
          date: selectedDate,
          calledLbs: 0,
        },
      ],
      { shouldDirty: true }
    );

    updateSelectedAllRation();
  }, [getValues, penIndex, rations, selectedDate, setValue, updateSelectedAllRation]);

  const deleteDrop = useCallback(
    (dropIndex: number) =>
      showConfirmationModal({
        title: 'Delete Drop',
        message: 'Are you sure you want to delete this drop?',
        confirmButton: 'Delete',
        onConfirm: () => {
          const drops = getValues().pens[penIndex].drops as PenDrop[];
          drops.splice(dropIndex, 1);
          setValue(`pens.${penIndex}.drops`, drops, { shouldDirty: true });

          updateSelectedAllRation();
        },
      }),
    [getValues, penIndex, setValue, updateSelectedAllRation]
  );

  const onDropCalledLbsChange = (dropIndex: number, value: number) => {
    if (value > 0) {
      setValue(`pens.${penIndex}.drops.${dropIndex}.lastOver0CalledLbs`, value, { shouldDirty: true });
    }
    updateTotalAndDmi();
  };

  return (
    <PenDropsListContainer data-testid={`call-feed-pen-${penIndex}`}>
      <PenDropsListHeader>
        <PenDropsListHeaderInfo>
          <PenDropsListHeaderPenName>{pen.name}</PenDropsListHeaderPenName>
          {!isPhoneSize && (
            <PenDropsListHeaderBunkScoreText data-testid="call-feed-pen-bunkScore-text">
              <BunkScoreSelectField control={control} penIndex={penIndex} isTodaySelected={isTodaySelected} />
            </PenDropsListHeaderBunkScoreText>
          )}
          <PenDropsListHeaderStatText data-testid="call-feed-pen-stat-text">
            <Typography sx={{ mr: 1 }}>{headerText} ｜</Typography>
            <LinkButton endIcon={<InfoOutlined />} onClick={() => showLotsInPenModal({ penId: pen.id })}>
              Lots
            </LinkButton>
          </PenDropsListHeaderStatText>
        </PenDropsListHeaderInfo>

        {!isPhoneSize && (
          <>
            {inDialog && (
              <Button variant="text" onClick={onHideDetails}>
                Hide Details
              </Button>
            )}
            {!inDialog && (
              <Button
                variant="text"
                onClick={() =>
                  showPenDropDetailsModal({
                    pens,
                    penIndex,
                    rations,
                    rationIngredientsMap,
                    selectedDate,
                    isTodaySelected,
                    formMethods,
                  })
                }
              >
                Show Details
              </Button>
            )}
          </>
        )}
      </PenDropsListHeader>

      <FieldsStack direction={isPhoneSize ? 'column' : 'row'} spacing={isPhoneSize ? 1 : 2}>
        {isPhoneSize && (
          <PenDropsListHeaderBunkScoreText data-testid="call-feed-pen-bunkScore-text">
            <Box sx={{ width: isPhoneSize ? '100%' : 160 }}>
              <BunkScoreSelectField control={control} penIndex={penIndex} isTodaySelected={isTodaySelected} />
            </Box>
          </PenDropsListHeaderBunkScoreText>
        )}
        <Box>
          <PenDropEditorContainer>
            {!isPhoneSize && (
              <PenDropEditorContainerHeader>
                <PenDropEditorContainerDropText>Detail Call</PenDropEditorContainerDropText>
              </PenDropEditorContainerHeader>
            )}
            <Stack spacing={1} direction={isPhoneSize ? 'row' : 'column'}>
              <StackFlexOne direction={'row'} spacing={1}>
                <Button
                  {...(callBy !== CallBy.DMI_PER_HEAD ? { color: 'secondary' } : {})}
                  variant="outlined"
                  sx={{ minWidth: 0, paddingX: 0, flex: 1 }}
                  data-testid={`pens.${penIndex}.callBy-dmi-button`}
                  onClick={() => updateCallBy(CallBy.DMI_PER_HEAD)}
                >
                  DMI / HD
                </Button>
                <Button
                  {...(callBy !== CallBy.AS_FED_PER_HEAD ? { color: 'secondary' } : {})}
                  variant="outlined"
                  sx={{ minWidth: 0, paddingX: 0, flex: 1 }}
                  data-testid={`pens.${penIndex}.callBy-asFed-button`}
                  onClick={() => updateCallBy(CallBy.AS_FED_PER_HEAD)}
                >
                  AF / HD
                </Button>
              </StackFlexOne>
              <BoxFlexOne>
                {callBy === CallBy.DMI_PER_HEAD && (
                  <ControlledNumberFieldWithIncrements
                    control={control}
                    name={`pens.${penIndex}.dmiPerHead`}
                    decimalPlaces={2}
                    disabled={disablePerHeadNumberFields}
                    increment={settingCallFeedDmiPerHeadIncrement}
                    onChange={onDmiPerHeadChange}
                  />
                )}
                {callBy === CallBy.AS_FED_PER_HEAD && (
                  <ControlledNumberFieldWithIncrements
                    control={control}
                    name={`pens.${penIndex}.asFedPerHead`}
                    decimalPlaces={2}
                    disabled={disablePerHeadNumberFields}
                    increment={settingCallFeedAfPerHeadIncrement}
                    onChange={onAsPerHeadChange}
                  />
                )}
              </BoxFlexOne>
            </Stack>
          </PenDropEditorContainer>
        </Box>

        <TotalCallAsFedBox>
          <PenDropEditorContainer>
            {!isPhoneSize && (
              <PenDropEditorContainerHeader>
                <PenDropEditorContainerDropText>Total Call AF</PenDropEditorContainerDropText>
              </PenDropEditorContainerHeader>
            )}
            <Stack spacing={1} direction={isPhoneSize ? 'row' : 'column'}>
              <BoxFlexOne>
                <ControlledSelectField
                  control={control}
                  name={`pens.${penIndex}.selectedAllRationId`}
                  disabled={drops.length === 0}
                  data-testid={`pens.${penIndex}.all-ration-select-field`}
                  onChange={(e) => onSelectedAllRationIdChange(Number(e.target.value))}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {rations.map((ration) => (
                    <MenuItem key={ration.id} value={ration.id}>
                      {ration.name}
                    </MenuItem>
                  ))}
                </ControlledSelectField>
              </BoxFlexOne>
              <BoxFlexOne>
                <ControlledNumberFieldWithIncrements
                  control={control}
                  name={`pens.${penIndex}.totalWeight`}
                  disabled={disableTotalCallNumberField}
                  increment={settingCallFeedAfIncrement}
                  onChange={onTotalWeightChange}
                />
              </BoxFlexOne>
            </Stack>
          </PenDropEditorContainer>
        </TotalCallAsFedBox>

        <PenDropsListDropsContainer>
          <PenDropsListDropsScroller>
            <PenDropsListDropsStack
              spacing={isPhoneSize ? 1 : 2}
              direction={isPhoneSize ? 'column' : 'row'}
              count={drops.length}
            >
              {/* deleting a drop did not actually delete when using `drops` var. */}
              {drops.map((drop, index) => (
                <PenDropEditor
                  key={drop.id ?? drop._uuid}
                  dropId={drop.id ?? drop._uuid ?? `drop-${index}`}
                  dropIndex={index}
                  penIndex={penIndex}
                  rations={rations}
                  control={control}
                  disabled={drop.load?.stage != null && drop.load?.stage != LoadStage.NotYetStarted}
                  onDeleteDrop={deleteDrop}
                  onCalledLbsChange={onDropCalledLbsChange}
                  onRationChange={(e: any) => {
                    // We need to update drop's revision accordingly
                    const rationRevision = rations.find((r) => r.id === Number(e.target.value))?.currentRevision;
                    setValue(`pens.${penIndex}.drops.${index}.rationRevision`, rationRevision, { shouldDirty: false });
                    updateSelectedAllRation();
                  }}
                />
              ))}

              <AddDropButtonWrap>
                <AddDropButton data-testid="call-feed-pen-add-drop-button" onClick={addDrop}>
                  <AddIcon />
                </AddDropButton>
              </AddDropButtonWrap>
            </PenDropsListDropsStack>
          </PenDropsListDropsScroller>
        </PenDropsListDropsContainer>
      </FieldsStack>
    </PenDropsListContainer>
  );
};

const BunkScoreSelectField = ({
  isTodaySelected,
  penIndex,
  control,
}: {
  isTodaySelected?: boolean;
  penIndex: number;
  control: Control<CalLFeedFormValues, any>;
}) => {
  if (!isTodaySelected) {
    return null;
  }
  return (
    <ControlledSelectField
      placeholder="Bunk Score"
      control={control}
      name={`pens.${penIndex}.bunkScore`}
      data-testid={`pens.${penIndex}.bunkScore`}
    >
      <MenuItem key="empty" value={''}>
        <em>-</em>
      </MenuItem>

      {bunkScoreOptions.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          data-testid={`bunk-scoring-tab-bunk-score-select-value-${penIndex}-${option.value}`}
        >
          {option.label}
        </MenuItem>
      ))}
    </ControlledSelectField>
  );
};
