import { formatNumber } from '@/components/helpers/format';
import { emDash } from '@/components/Typography/emDash';
import { GetPenDropDetailsDialogQuery, PenDropAtDate } from '@/web-types';
import { styled } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { sumBy } from 'lodash';
import { isPresent } from 'ts-is-present';

const StyledTableCell = styled(TableCell)({
  paddingTop: '8px',
  paddingBottom: '8px',
});

interface PenDropDetailsTableProps {
  data: PenDropAtDate[];
  /** Rations, in the order they first appear in `data` */
  rations: GetPenDropDetailsDialogQuery['getPenDropDetails']['rations'];
  rationMap: Map<number, GetPenDropDetailsDialogQuery['getPenDropDetails']['rations'][0]>;
}

export const PenDropDetailsTable = ({ data, rations, rationMap }: PenDropDetailsTableProps) => {
  const rationIndex = new Map(rations.map((r, i) => [r.id, i]));
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Head</StyledTableCell>
            <StyledTableCell>Ration</StyledTableCell>
            <StyledTableCell>Bunk Score</StyledTableCell>
            <StyledTableCell>Call</StyledTableCell>
            <StyledTableCell>Change</StyledTableCell>
            <StyledTableCell>As Fed</StyledTableCell>
            <StyledTableCell>AF/HD</StyledTableCell>
            <StyledTableCell>DMI/HD</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => {
            let fedLbsChange = '+0';
            if (item.fedLbsChange) {
              fedLbsChange = formatNumber(item.fedLbsChange);
              if (item.fedLbsChange > 0) {
                fedLbsChange = `+${formatNumber(item.fedLbsChange)}`;
              }
            }

            const rationNames = item.fedLbsPerHeadOnDate
              .map(({ rationId }) => rationMap.get(rationId))
              .filter(isPresent)
              .sort((a, b) => rationIndex.get(a.id)! - rationIndex.get(b.id)!)
              .map((r) => r.name)
              .join(', ');

            return (
              <TableRow key={item.date}>
                <StyledTableCell>{dayjs(item.date).format('MM/DD')}</StyledTableCell>
                <StyledTableCell>{formatNumber(item.numHeads)}</StyledTableCell>
                <StyledTableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: { xs: 'none', md: 120 },
                  }}
                  title={rationNames}
                >
                  {rationNames}
                </StyledTableCell>
                <StyledTableCell>{item.bunkScore ?? emDash}</StyledTableCell>
                <StyledTableCell>{formatNumber(item.calledLbsOnDate)}</StyledTableCell>
                <StyledTableCell>{fedLbsChange}</StyledTableCell>
                <StyledTableCell>{formatNumber(item.fedLbsOnDate)}</StyledTableCell>
                <StyledTableCell>{formatNumber(sumBy(item.fedLbsPerHeadOnDate, (r) => r.amountLbs))}</StyledTableCell>
                <StyledTableCell>{formatNumber(sumBy(item.dmiLbsPerHeadOnDate, (r) => r.amountLbs))}</StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
