import '@/components/registerChartjs';
import { GetPenDropDetailsDialogQuery, PenDropAtDate } from '@/web-types';
import { DefaultDataPoint } from 'chart.js';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartProps } from 'react-chartjs-2/dist/types';
import { formatNumber } from '../helpers/format';

type BarChartProps = ChartProps<'bar', DefaultDataPoint<'bar'>, unknown>;
type BarChartOptions = BarChartProps['options'];
type BarChartData = BarChartProps['data'];
type RationMap = Map<number, GetPenDropDetailsDialogQuery['getPenDropDetails']['rations'][0]>;

interface PenHistoryGraphProps {
  penDropAtDates: PenDropAtDate[];
  rationMap: RationMap;
  showDmi: boolean;
}

const DATASETS = {
  HISTORICAL_MAX: { label: 'Historical Max', backgroundColor: '#FBD34D', borderColor: '#FBD34D' },
  CALLED: { label: 'Feed Call', backgroundColor: '#5842BD', borderColor: '#5842BD' },
  FED_PER_HEAD: { label: 'As Fed / HD', backgroundColor: '#62AAF1', borerColor: '' },
  DMI_PER_HEAD: { label: 'DMI / HD', backgroundColor: '#62AAF1', borerColor: '' },
};

export const PenHistoryGraph = ({ penDropAtDates, rationMap, showDmi }: PenHistoryGraphProps) => {
  const options = useMemo<BarChartOptions>(
    () =>
      ({
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          tooltip: {
            titleAlign: 'center',
            bodyAlign: 'right',
            callbacks: {
              label: (context) => `${context.dataset.label || ''}: ${formatNumber(context.parsed.y)}`,
            },
          },
        },
        scales: {
          x: { stacked: true },
          y: { stacked: true },
        },
      }) as BarChartOptions,
    []
  );
  const chartData = useMemo(() => getChartData(penDropAtDates, rationMap, showDmi), [penDropAtDates, showDmi]);
  return <Bar options={options} data={chartData} />;
};

const getChartData = (penDropAtDates: PenDropAtDate[], rationMap: RationMap, showDmi: boolean) => {
  const rationDatasets = getRationDatasets(penDropAtDates, rationMap, showDmi);
  return {
    labels: penDropAtDates.map((item) => dayjs(item.date).format('MM/DD')),
    datasets: [
      {
        pointRadius: 0,
        type: 'line',
        label: DATASETS.HISTORICAL_MAX.label,
        backgroundColor: DATASETS.HISTORICAL_MAX.backgroundColor,
        borderColor: DATASETS.HISTORICAL_MAX.borderColor,
        borderWidth: 2,
        fill: false,
        data: penDropAtDates.map((item) => formatNumber(item.historicalMaxFedLbsPerHead)),
        borderDash: [5, 5],
        stack: 'historicalMax',
      },
      {
        type: 'line',
        label: DATASETS.CALLED.label,
        backgroundColor: DATASETS.CALLED.backgroundColor,
        borderColor: DATASETS.CALLED.borderColor,
        borderWidth: 2,
        fill: false,
        data: penDropAtDates.map((item) => formatNumber(item.calledLbsPerHeadOnDate)),
        borderDash: [0, 6],
        stack: 'calledLbs',
      },
      ...rationDatasets,
    ],
  } as BarChartData;
};

export const getRationDatasets = (penDropAtDates: PenDropAtDate[], rationMap: RationMap, showDmi: boolean) => {
  const dataGetter = showDmi
    ? (x: PenDropAtDate) => x.dmiLbsPerHeadOnDate
    : (x: PenDropAtDate) => x.fedLbsPerHeadOnDate;

  const allRationIds = [
    ...new Set(penDropAtDates.map((day) => dataGetter(day).map((rations) => rations.rationId)).flat()),
  ];

  return allRationIds.map((rationId) => {
    const ration = rationMap.get(rationId);
    return {
      label: ration?.name ?? 'Unknown ration',
      backgroundColor: ration?.color ?? DATASETS.FED_PER_HEAD.backgroundColor,
      data: penDropAtDates.map((dayData, dayIdx) => {
        const val = dataGetter(dayData).find((r) => r.rationId === rationId)?.amountLbs ?? 0;
        return val;
      }),
    };
  });
};
