import { useGetPenDropDetailsDialogQuery } from '@/web-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import gql from 'graphql-tag';
import { useCallback, useMemo, useState } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { DialogContainer } from '../Dialog';
import ModalManager, { useModal } from '@ebay/nice-modal-react';
import { PenDropsListProps } from '@/components/CallFeedTab/types';
import { DETAILS_QUERY_LIMIT } from '@/components/CallFeedTab/constants';
import { PenDropsList } from '@/components/CallFeedTab/PenDropsList';
import { LoadingScreen } from '@/components/LoadingScreen';
import { ErrorScreen } from '@/components/ErrorScreen';
import { PrevNextButtons } from '@/components/CallFeedTab/styledComponents';
import { PenHistoryGraph } from '@/components/CallFeedTab/PenHistoryGraph';
import { PenDropDetailsTable } from '@/components/CallFeedTab/PenDropDetailsTable';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { createMap } from '@/common/utils/map';
import { useWatch } from 'react-hook-form';
import { callFeedKvStore } from './callFeedKvStore';

gql`
  query GetPenDropDetailsDialog($penId: Int!, $limit: Int!) {
    getPenDropDetails(penId: $penId, limit: $limit) {
      rations {
        id
        name
        color
      }
      penDropAtDates {
        date
        bunkScore
        numHeads
        dmiLbsPerHeadOnDate {
          rationId
          amountLbs
        }
        fedLbsPerHeadOnDate {
          rationId
          amountLbs
        }
        fedLbsChange
        fedLbsOnDate
        calledLbsOnDate
        calledLbsPerHeadOnDate
        historicalMaxFedLbsPerHead
      }
    }
  }
`;

interface ModalProps
  extends Pick<
    PenDropsListProps,
    'pens' | 'rations' | 'rationIngredientsMap' | 'isTodaySelected' | 'selectedDate' | 'formMethods'
  > {
  penIndex: number;
}

export const showPenDropDetailsModal = (props?: ModalProps) => ModalManager.show(PenDropDetailsModal, props);

const PenDropDetailsModal = ModalManager.create<ModalProps>(
  ({ pens, penIndex: originalPenIndex, rations, rationIngredientsMap, selectedDate, isTodaySelected, formMethods }) => {
    const [penIndex, setPenIndex] = useState(() => originalPenIndex);
    const pen = pens[penIndex];
    const modal = useModal();
    const { control, setValue } = formMethods;
    const [graphShowDmi] = useWatch({ control, name: [`graphShowDmi`] });

    const [{ fetching, data, error }] = useGetPenDropDetailsDialogQuery({
      variables: {
        penId: pen?.id,
        limit: DETAILS_QUERY_LIMIT,
      },
    });

    const graphData = useMemo(
      () => [...(data?.getPenDropDetails?.penDropAtDates ?? [])].sort((a, b) => (a.date < b.date ? -1 : 1)),
      [data?.getPenDropDetails]
    );
    const tableData = useMemo(() => {
      const items = [...(data?.getPenDropDetails?.penDropAtDates ?? [])].sort((a, b) => (a.date < b.date ? 1 : -1));
      items.length = Math.min(items.length, 10);
      return items;
    }, [data?.getPenDropDetails]);

    const feedHistoryRations = useMemo(() => {
      return data?.getPenDropDetails?.rations ?? [];
    }, [data?.getPenDropDetails]);

    const feedHistoryRationMap = useMemo(() => {
      return createMap(data?.getPenDropDetails?.rations ?? [], (r) => r.id);
    }, [data?.getPenDropDetails]);

    const updateGraphShowDmi = useCallback(
      (graphShowDmi: boolean) => {
        setValue(`graphShowDmi`, graphShowDmi, { shouldDirty: false });
        callFeedKvStore.set({ graphShowDmi });
      },
      [setValue]
    );

    return (
      <Dialog onClose={modal.remove} open={modal.visible} fullScreen>
        <DialogContainer data-testid="pen-drop-details-dialog" sx={{ minHeight: '100%' }}>
          <PrevNextButtons>
            <Button
              variant="text"
              disabled={penIndex === 0}
              startIcon={<NavigateBeforeIcon fontSize="small" />}
              onClick={() => {
                setPenIndex((prev) => prev - 1);
              }}
            >
              Previous Pen
            </Button>
            <Button
              variant="text"
              disabled={penIndex === pens.length - 1}
              endIcon={<NavigateNextIcon fontSize="small" />}
              onClick={() => {
                setPenIndex((prev) => prev + 1);
              }}
            >
              Next Pen
            </Button>
          </PrevNextButtons>
          <Box sx={{ md: { height: 188 }, mb: 2 }}>
            <PenDropsList
              key={`pens.${penIndex}`}
              pens={pens}
              penIndex={penIndex}
              rations={rations}
              rationIngredientsMap={rationIngredientsMap}
              inDialog
              isTodaySelected={isTodaySelected}
              selectedDate={selectedDate}
              onHideDetails={modal.remove}
              formMethods={formMethods}
            />
          </Box>

          {fetching && <LoadingScreen />}
          {!fetching && error && <ErrorScreen />}
          {!fetching && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <PenDropDetailsTable data={tableData} rations={feedHistoryRations} rationMap={feedHistoryRationMap} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="column" sx={{ minHeight: 300, height: { xs: 450, md: '100%' } }}>
                  <Box flex={1}>
                    <PenHistoryGraph
                      penDropAtDates={graphData}
                      rationMap={feedHistoryRationMap}
                      showDmi={graphShowDmi}
                    />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={graphShowDmi}
                          onChange={(e) => updateGraphShowDmi(Boolean(e.target.checked))}
                        />
                      }
                      label="DMI"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContainer>
      </Dialog>
    );
  }
);
