import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const FlashMessageContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.yellow[100],
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  borderRadius: theme.spacing(1),
}));

export const FlashMessageInfoIcon = styled(InfoIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));
FlashMessageInfoIcon.defaultProps = {
  fontSize: 'small',
};

export const BoxRelative = styled(Box)(() => ({
  position: 'relative',
}));

export const CallFeedTabFields = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
}));

export const CallFeedTabHeader = styled(Stack)(({ theme }) => ({
  position: 'sticky',
  zIndex: 10,
  background: '#ffffff',
  top: 0,
  left: 0,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3),
  width: '100%',
  fontSize: '1rem',
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(1)} ${theme.spacing(3)}`,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    padding: theme.spacing(2),
    mb: 2,
  },
}));
CallFeedTabHeader.defaultProps = {
  direction: 'row',
};

export const PrevNextButtons = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const CallFeedTabPensList = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));
CallFeedTabPensList.defaultProps = {
  spacing: 4,
};

export const PenDropsListContainer = styled(Stack)(({ theme }) => ({
  background: theme.palette.grey[50],
  borderRadius: theme.spacing(1),
  overflow: 'hidden',
  border: `1px solid ${theme.palette.grey[300]}`,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
}));
PenDropsListContainer.defaultProps = {
  direction: 'column',
};

export const PenDropsListHeader = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  height: '46px',
  background: theme.palette.grey[300],
  justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));
PenDropsListHeader.defaultProps = {
  direction: 'row',
  spacing: 2,
};

export const PenDropsListHeaderInfo = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
}));
PenDropsListHeaderInfo.defaultProps = {
  direction: 'row',
  spacing: 2,
};

export const PenDropsListHeaderTotalWeightTextValue = styled(Box)(({ theme }) => ({
  width: theme.spacing(8),
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    width: theme.spacing(20),
  },
}));

export const PenDropsListHeaderPenName = styled(Typography)(({ theme }) => ({
  paddingBottom: 0,
  width: 180,

  [theme.breakpoints.down('md')]: {
    width: 'auto',
  },
}));
PenDropsListHeaderPenName.defaultProps = {
  variant: 'h2',
  color: 'primary.main',
};

export const PenDropsListHeaderStatText = styled(Typography)(({ theme }) => ({
  paddingBottom: 0,
  fontSize: '1rem',
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    width: 'auto',
  },
}));

export const PenDropsListHeaderBunkScoreText = styled(Box)(({ theme }) => ({
  fontSize: '1rem',
  width: 180,

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const PenDropsListHeaderWeightText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.grey[600],
  paddingBottom: theme.spacing(2),
}));

export const PenDropsListHeaderTotalWeightText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    paddingBottom: 0,
  },
}));
PenDropsListHeaderTotalWeightText.defaultProps = {
  variant: 'h3',
};

export const PenDropsListHeaderTotalWeightTextLabel = styled(Box)(({ theme }) => ({
  minWidth: theme.spacing(10),
  height: '100%',
  display: 'inline-flex',
  alignItems: 'center',
}));

export const PenDropsListHeaderDmiText = styled(Typography)(() => ({
  fontWeight: 400,
}));
PenDropsListHeaderDmiText.defaultProps = {
  variant: 'h3',
};

export const PenDropsListDropsContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'count',
})<{ count?: number }>(({ count = 0, theme }) => ({
  position: 'relative',
  background: theme.palette.grey[50],
  height: '140px',
  width: '100%',
  overflow: 'hidden',
  borderRadius: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    overflow: 'visible',
  },
}));

export const PenDropsListDropsScroller = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  overflowX: 'auto',
  overflowY: 'hidden',
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    height: 'auto',
  },
}));

export const PenDropsListDropsStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'count',
})<{ count?: number }>(({ count = 0, theme }) => ({
  width: `${174 * (count + 1) + 16 * count}px`,
  height: '100%',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const AddDropButtonWrap = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const AddDropButton = styled(Button)(({ theme }) => ({
  padding: 0,
  minWidth: theme.spacing(8),
  width: theme.spacing(8),
  height: '40px',
  border: `1px dashed ${theme.palette.grey[500]}`,
}));
AddDropButton.defaultProps = {
  variant: 'outlined',
};

export const PenDropEditorContainer = styled(Stack)(({ theme }) => ({
  width: '180px',
  height: '132px',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: 'auto',
  },
}));

export const PenDropEditorContainerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '32px',
}));

export const PenDropEditorContainerDropText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: 1,
  flex: 1,
  textAlign: 'center',

  [theme.breakpoints.down('md')]: {
    textAlign: 'left',
  },
}));

export const CallFeedDialogContent = styled(Box)(() => ({
  textAlign: 'center',
}));

export const CallFeedDialogButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: theme.spacing(2),
}));

export const BoxFlexOne = styled(Box)(() => ({
  flex: 1,
}));

export const StackFlexOne = styled(Stack)(() => ({
  flex: 1,
}));

export const FieldsStack = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
  },
}));

export const TotalCallAsFedBox = styled(Box)(({ theme }) => ({
  paddingRight: theme.spacing(2),
  borderRight: `1px solid ${theme.palette.grey[300]}`,
  [theme.breakpoints.down('md')]: {
    paddingRight: 0,
    paddingBottom: theme.spacing(1),
    borderRight: 'none',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));

export const DeleteIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  svg: {
    fontSize: `20px`,
  },
}));

export const PenDropsListWrap = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPhoneSize',
})<{ isPhoneSize?: boolean }>(({ theme, isPhoneSize = false }) => ({
  paddingTop: theme.spacing(isPhoneSize ? 1 : 2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));
